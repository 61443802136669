import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import 'photoswipe/dist/photoswipe.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

export default function ArtGallery(props) {
  const isotope = React.useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");

//   useEffect(() => {
//     setTimeout(() => {
//         isotope.current = new Isotope(".library-2", {
//         itemSelector: ".art",
//         layoutMode: "masonry",
//         masonry: {
//             gutter: 20
//         }
//         });
//     }, 1000)

//   }, []);

//   useEffect(() => {
//     setTimeout(() => {
//         isotope.current.arrange();
//     }, 1000)

//   }, [props.photos]);

  return (
    <Gallery options={{wheelToZoom: true}}>
        <div className="library-2 animate__animated animate__fadeIn animate__delay-2s">
        {props.photos
            ? props.photos.map((photo, index) => (
            <Item
                original={photo.download_url}
                thumbnail={photo.download_url}
                height={index + 1 === 3 | index + 1 === 13 ? ((((window.innerWidth / photo.width) * photo.height) * 0.81) / 3) * 2 : (((window.innerWidth / photo.width) * photo.height) * 0.81)}
                width={index + 1 === 3 | index + 1 === 13 ? ((((window.innerWidth / photo.width) * photo.width) * 0.81) / 3) * 2 : (((window.innerWidth / photo.width) * photo.width) * 0.81)}
            >
                {({ ref, open }) => (
                    <div
                    className={`column art`}
                    key={photo.id}
                    ref={ref} onClick={open}
                    >
                    <LazyLoadImage
                        className="animate__animated animate__fadeIn animate__delay-1s"
                        src={photo.download_url}
                        style={{
                            height: "auto",
                            width: "100%",
                        }}
                        height={index + 1 === 3 | index + 1 === 13 ? ((((window.innerWidth / photo.width) * photo.height) * 0.81) / 3) * 2 + 20 : (((window.innerWidth / photo.width) * photo.height) * 0.81) / 3}
                        width={index + 1 === 3 | index + 1 === 13 ? ((((window.innerWidth / photo.width) * photo.width) * 0.81) / 3) * 2 + 20 : (((window.innerWidth / photo.width) * photo.width) * 0.81) / 3}
                    />
                    </div>
                )}
            </Item>
            ))
            : ""}
        </div>
    </Gallery>
  );
}
