import "./styles.css";
import VideoList from "./components/VideoList";
import ArtGallery from "./components/ArtGallery";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faArrowRight,
  faArrowDown,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import React, { useState, useRef, useEffect } from "react";
import "animate.css";
import tom from "./tom.png";
import plush from "./plush.webp";
import banner from "./banner.png";
import banner2 from "./banner2.jpg";
import banner3 from "./banner3.jpg";
import Tilt from "react-parallax-tilt";
import { ReactComponent as Turkey } from "./turkey.svg";
import images from "./data.jsx";

export default function App() {
  const presetColors = [
    "#ffbe0b",
    "#fb5607",
    "#ff006e",
    "#8338ec",
    "#3a86ff",
    "#00AF54",
    "#469DBD",
    "#FB07E1",
  ];

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const changeName = (e) => setName(e.target.value);
  const changeEmail = (e) => setEmail(e.target.value);
  const changeSubject = (e) => setSubject(e.target.value);
  const changeMessage = (e) => setMessage(e.target.value);

  const [theme, setTheme] = useState("dark");
  const [color, setColor] = useState(
    presetColors[Math.floor(Math.random() * presetColors.length)]
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalVideo, setModalVideo] = useState("");
  const [quote, setQuote] = useState({
    content: "While you're scrolling, leave me a message!",
    author: "Turkey Tom",
  });
  const [top, setTop] = useState(0);

  const [popup, setPopup] = useState(true);

  const [videos, setVideos] = useState(0);
  const [isLive, setIsLive] = useState("");

  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [photos, setPhotos] = useState([]);

  // initialize an Isotope object with configs
  useEffect(() => {
    // Put your channel id here and you are good to go
    var channelID = "UCDKJdFer1phQI95UinPZehw";
    var channelID2 = "UCCAfRoTJrKPbSrh_Eg3i4vg";
    var channelID3 = "UC64yhguM6CL-r7OKaKSTfyg";

    setPhotos(images);

    Promise.all([
      fetch(
        "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D" +
          channelID
      ).then((data) => data.json()),
      fetch(
        "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D" +
          channelID2
      ).then((data) => data.json()),
      fetch(
        "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id=" +
          channelID3
      ).then((data) => data.json()),
      fetch("/private.txt").then((data) => data.text()),
    ])
      .then(([turkeyTom, tomDark, tomIrl, privateVideos]) => {
        let objecto = tomDark;

        objecto.items = [...turkeyTom.items, ...tomDark.items, ...tomIrl.items];

        console.log(objecto.items);

        objecto.items.sort((a, b) => {
          return new Date(b.pubDate) - new Date(a.pubDate);
        });

        objecto.items = objecto.items.filter((item) => {
          if (
            !privateVideos.includes(item.guid.replace("yt:video:", "").trim())
          ) {
            return item;
          }
        });

        return objecto;
      })
      .then((data) => {
        setVideos(data);
      });

    fetch(
      "https://yt.lemnoslife.com/noKey/search?part=snippet&channelId=" +
        channelID2 +
        "&type=video&eventType=live"
    )
      .then((data) => data.json())
      .then((otherData) => {
        const live = otherData;

        if (live && live.items.length !== 0) {
          // console.log(live);
          // setIsLive(live.items[0]);
          // fetch("/private.php", {
          //   method: "POST",
          //   body: JSON.stringify({
          //     password: "live",
          //     video: live.items[0].id.videoId,
          //   }),
          // }).then((data) => {});
        }
      })
      .catch((error) => console.log(error));

    const onLocationChange = () => {
      window.scrollTo(0, 0);
      setCurrentPath(window.location.pathname);
    };
    window.addEventListener("navigate", onLocationChange);
    window.addEventListener("popstate", onLocationChange);
  }, []);

  const themeRef = useRef();

  const changeTheme = (theme) => {
    setTheme(theme ? "dark" : "light");
  };

  const handleChangeComplete = (color) => {
    setColor(
      `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
    );
  };

  const modalClick = (video) => {
    setModalOpen(true);
    //setTop(window.scrollY);

    setTimeout(() => {
      setModalVideo(video);
    }, 1000);
  };

  const modalClose = () => {
    setModalOpen(false);

    setTimeout(() => {
      //const oldTop = top;
      //setTop(0);
      //window.scrollTo(0, oldTop);
      setModalVideo("");
    }, 300);
  };

  document.addEventListener("keyup", (e) => {
    if (e.keyCode === 27) {
      window.location.reload();
    }
  });

  const [plushClass, setPlushClass] = useState(
    "animate__animated animate__fadeIn animate__delay-1s"
  );
  const [formClass, setFormClass] = useState("");

  const formSend = () => {
    if ((name !== "") & (email !== "") & (subject !== "") & (message !== "")) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      fetch("/mail.php", {
        method: "POST",
        body: JSON.stringify({
          subject: subject,
          message: message,
          email: email,
          name: name,
        }),
      }).then((data) => {});

      setFormClass("animate__animated animate__fadeOut formHide");

      setQuote({
        content: "Appreciate the message! I'll get back to you soon",
        author: "Tom Dark",
      });

      setPlushClass("animate__animated animate__rubberBand");

      setTimeout(() => {
        setPlushClass("animate__animated");
      }, 1000);
    }
  };

  const animate = async () => {
    const response = await fetch("https://api.quotable.io/random");
    const data = await response.json();

    if (response.ok) {
      setQuote(data);
    }

    setPlushClass("animate__animated animate__rubberBand");

    setTimeout(() => {
      setPlushClass("animate__animated");
    }, 1000);
  };

  const [currentFilter, setCurrentFilter] = useState("*");
  const filter = (filter) => {
    setCurrentFilter(filter);
  };

  const loadLink = (event, to) => {
    event.preventDefault();
    window.history.pushState({}, "", to);
    const navigationEvent = new PopStateEvent("navigate");
    window.dispatchEvent(navigationEvent);
  };

  const popupClick = (video) => {
    setPopup(false);
  };

  return (
    <div
      className={`theme-wrapper theme-${theme} ${modalOpen ? "open" : ""}`}
      onClick={modalOpen ? modalClose : () => {}}
    >
      <style>{`:root{--theme-color: ${color};}`}</style>
      <div className="close" onClick={modalClose}>
        <FontAwesomeIcon icon={faClose} />
      </div>

      <div className="modal">
        {modalVideo !== "" ? (
          <span>
            <iframe
              className="animate__animated animate__fadeIn"
              src={`https://www.youtube.com/embed/${modalVideo.replace(
                "yt:video:",
                ""
              )}?autoplay=1`}
              title="Modal"
            />
          </span>
        ) : (
          ""
        )}
      </div>

      {popup === "im gay" ? (
        <div className="popup--wrapper">
          <div className="popup--overlay" onClick={popupClick}></div>

          <div className="popup">
            <img src="https://cdn.shopify.com/s/files/1/0125/8261/7145/files/h62vFoANbqogD0ZN7ENqxT1iylLDY0lAwZh2G5fFlo8_1000x.png.webp?v=1688589704" />

            <h2>
              **NEW** Turkey Tom Classic Plush
              <div>available for a limited time only!</div>
            </h2>

            <a
              href="https://www.makeship.com/products/turkey-tom-classic-plush"
              className="button"
              target="_blank"
            >
              Check It Out!
            </a>
            <br></br>
            <span onClick={popupClick}>x</span>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="theme" style={{ top: -top }} ref={themeRef}>
        <Navigation
          theme={theme}
          color={color}
          handleChangeComplete={handleChangeComplete}
          changeTheme={changeTheme}
          presetColors={presetColors}
          isLive={isLive}
        />

        {currentPath === "/art-contest" ? (
          <div className="">
            <a
              href="/"
              className="back animate__animated animate__fadeIn animate__delay-1s"
              onClick={(event) => loadLink(event, "/")}
            >
              <span className="back-button">
                <h6>Back to</h6>
                The Homepage
              </span>
            </a>

            <div className="hero">
              <div
                className="hero-video"
                style={{
                  backgroundImage: `url(${banner2})`,
                }}
              ></div>

              <div className="hero-text animate__animated animate__fadeIn animate__delay-1s">
                <div className="hero-inner">
                  <img
                    className="animate__animated animate__tada animate__delay-4s"
                    src={tom}
                    alt=""
                  />

                  <h6>The reuslts are in for</h6>

                  <h2>The Community Art Contest</h2>

                  <h5>Scroll down to view the submissions</h5>
                </div>
              </div>
            </div>

            <div className="inner inner-video" style={{ alignItems: "center" }}>
              <h1 className="animate__animated animate__fadeIn animate__delay-1s">
                Art Contest Submissions
              </h1>
            </div>

            <ArtGallery photos={photos} />
          </div>
        ) : (
          <div>
            <a
              href="art-contest"
              className="back animate__animated animate__fadeIn animate__delay-1"
              onClick={(event) => loadLink(event, "art-contest")}
            >
              <span className="back-button">
                <h6>Check out</h6>
                The Art Contest
              </span>
            </a>

            <div
              className="hero"
              //style={
              //{
              //background: `url(https://i.ytimg.com/vi/mME0NXRQHuc/hqdefault.jpg?sqp=-oaymwEjCNACELwBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLDBdLcc2xTs8IczMoGpa85yazqXYg)`
              //}
              //}
            >
              <div
                className="hero-video"
                style={{
                  backgroundImage: `url(${banner3})`,
                }}
              >
                {isLive !== "" ? (
                  <iframe
                    className="animate__animated animate__fadeIn animate__delay-1s"
                    src={`https://www.youtube.com/embed/${isLive.id.videoId}?autoplay=1&mute=1&loop=1`}
                    title="Hero"
                  />
                ) : (
                  <iframe
                    className="animate__animated animate__fadeIn animate__delay-1s"
                    src={`https://www.youtube.com/embed/${
                      videos
                        ? videos.items[0].guid.replace("yt:video:", "")
                        : ""
                    }?autoplay=1&mute=1&loop=1`}
                    title="Hero"
                  />
                )}
              </div>

              <div
                className="hero-text animate__animated animate__fadeIn animate__delay-1s"
                onClick={() => {
                  isLive !== ""
                    ? modalClick(isLive.id.videoId)
                    : modalClick(videos ? videos.items[0].guid : "");
                }}
              >
                <div className="hero-inner">
                  <img
                    className="animate__animated animate__tada animate__delay-4s"
                    src={tom}
                    alt=""
                  />

                  <h6>{isLive !== "" ? "Live" : "Now playing"}</h6>

                  {isLive !== "" ? (
                    <h2>
                      {videos
                        ? isLive.snippet.title.replace(/&amp;/g, "&")
                        : ""}
                    </h2>
                  ) : (
                    <h2>
                      {videos
                        ? videos.items[0].title.replace(/&amp;/g, "&")
                        : ""}
                    </h2>
                  )}

                  <h5>
                    {isLive !== ""
                      ? "Join the stream"
                      : "Watch the latest video"}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </h5>
                </div>
              </div>
            </div>

            <div className="inner inner-video" style={{ alignItems: "center" }}>
              <h1 className="animate__animated animate__fadeIn animate__delay-1s">
                Videos
              </h1>

              <div className="video-buttons animate__animated animate__fadeIn animate__delay-1s">
                <button
                  className={currentFilter === "*" ? "active" : ""}
                  onClick={() => filter("*")}
                >
                  Latest Uploads
                </button>
                <button
                  className={currentFilter === "turkeytom" ? "active" : ""}
                  onClick={() => filter("turkeytom")}
                >
                  Turkey Tom
                </button>
                <button
                  className={currentFilter === "tomdark" ? "active" : ""}
                  onClick={() => filter("tomdark")}
                >
                  Tom Dark
                </button>
                <button
                  className={currentFilter === "tomirl" ? "active" : ""}
                  onClick={() => filter("tomirl")}
                >
                  TomIRL
                </button>
              </div>
            </div>

            <VideoList
              filter={currentFilter}
              videos={videos}
              handleModalClick={modalClick}
            />
            {/* 
            <div className="banner-wrapper animate__animated animate__fadeIn animate__delay-1s">
              <img className="banner" src={banner} />

              <a
                href="http://turkeytom.gg"
                className="hero-text animate__animated animate__fadeIn animate__delay-1s"
                target="_blank"
              >
                <div className="hero-inner">
                  <img
                    className="animate__animated animate__tada animate__delay-6s"
                    src={tom}
                    alt=""
                  />

                  <h6>Annoucement</h6>

                  <h2>
                    New Discord Server <FontAwesomeIcon icon={faDiscord} />
                  </h2>

                  <h5>
                    Join the community
                    <FontAwesomeIcon icon={faArrowRight} />
                  </h5>
                </div>
              </a>
            </div> */}

            <div className="inner inner-mini">
              <h1 className="animate__animated animate__fadeIn animate__delay-1s">
                Contact
              </h1>
            </div>

            <div className="inner inner-mini animate__animated animate__fadeIn animate__delay-1s">
              <div className="formWrapper">
                <div className={formClass}>
                  <input
                    type="text"
                    placeholder="Name"
                    onChange={changeName}
                    value={name}
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    onChange={changeEmail}
                    value={email}
                  />
                  <input
                    type="text"
                    placeholder="Subject"
                    onChange={changeSubject}
                    value={subject}
                  />
                  <textarea
                    placeholder="Message"
                    onChange={changeMessage}
                    value={message}
                  />
                  <button onClick={formSend}>
                    Send
                    {(name === "") |
                    (email === "") |
                    (subject === "") |
                    (message === "") ? (
                      <span>You must fill out all of the fields</span>
                    ) : (
                      ""
                    )}
                  </button>
                </div>

                {formClass ? (
                  <div className="animate__animated animate__fadeIn animate__delay-1s thankyou">
                    <Turkey />
                    Thank you!
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="plush" onClick={animate}>
                <Tilt scale="1.2">
                  <blockquote className="speech-bubble">
                    <p>{quote.content}</p>
                    <cite>{quote.author}</cite>
                  </blockquote>
                  <img
                    className={plushClass}
                    src={plush}
                    alt="Turkey Tom Plush"
                  />

                  <div className="price animate__animated animate__fadeIn animate__delay-1s">
                    $20
                  </div>

                  <div className="plush-text">Turkey Tom Plush</div>
                </Tilt>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
}
